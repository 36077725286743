import React, { PropTypes } from 'react';

import myQuotes from '../json/quotes.json';

let myNewQuote;

let myAuthor;
let myQuote;
let myStatus;


class Header extends React.Component {

	constructor() {
		super();
	}
	componentDidMount() {
		this.setState({ myWelcomeVisible: false });

		myNewQuote = (Math.floor(Math.random() * myQuotes.length));

		myAuthor = myQuotes[myNewQuote].author;
		myQuote = myQuotes[myNewQuote].quote;
		myStatus = myQuotes[myNewQuote].status;
	}

	render() {

		return (
      <div className="header">
			<img src="resources/background/desktop.png" className="headerBackground">
			</img>
				<div className="headerContent">
					<div className="welcomeMessage">
							<div className="quotes">
								<div className="quote">{myQuote}</div>
								<div className="author">{myAuthor}</div>
							</div>
					</div>
				</div>

      </div>
		)
	}
}

export default Header;
