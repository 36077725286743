import React, { PropTypes } from 'react';
import Scroll from 'react-scroll';
import ClassNames from 'classnames'

import myProjects from '../json/myProjects.json';

let projectJSONNumber;
const currentDigit = document.getElementsByClassName("number");
let eventTargetId;
let eventKey;
let projectId;
let projectNr;

const date = new Date();
const year = date.getFullYear();

const myDarkMainColor = '#041f28';
const myVeryLightMainColor = '#CFDADE';

const Link = Scroll.Link;
const Element = Scroll.Element;
const Events = Scroll.Events;
const scroll = Scroll.animateScroll;
const scrollSpy = Scroll.scrollSpy;

class Projects extends React.Component {

	constructor() {
		super();
		this.state = {
			myProjectVisible: "null"
		};
	}

	componentDidMount() {
    window.addEventListener('keydown', this.handleEvent.bind(this));
		document.getElementById('0') ?
			document.getElementById('0').id = 'zero' : ''

		console.log('mount');
  }

	makeItDark(evt) {
		if (evt.target.id == 'mailIcon') {
			document.getElementById('mailIcon').src="resources/mailIcon.svg";
		}
		else if (evt.target.id == 'facebookIcon') {
			document.getElementById('facebookIcon').src="resources/facebookIcon.svg";
		}
		else if (evt.target.id == 'githubIcon') {
			document.getElementById('githubIcon').src="resources/githubIcon.svg";
		}
		else if (evt.target.className == 'lecLogo') {
			document.getElementById('zero').src="resources/lecLogo.svg";
		}
		else if (evt.target.className == 'contributors' || evt.target.className == 'links') {
			document.getElementById(evt.target.id).style.color = myDarkMainColor;
		}
	}

	makeItLight(evt) {
		if (evt.target.id == 'mailIcon') {
			document.getElementById('mailIcon').src="resources/mailIconLight.svg";
		}
		else if (evt.target.id == 'facebookIcon') {
			document.getElementById('facebookIcon').src="resources/facebookIconLight.svg";
		}
		else if (evt.target.id == 'githubIcon') {
			document.getElementById('githubIcon').src="resources/githubIconLight.svg";
		}
		else if (evt.target.className == 'lecLogo') {
			document.getElementById('zero').src="resources/lecLogoLight.svg";
		}
		else if (evt.target.className == 'contributors' || evt.target.className == 'links') {
			document.getElementById(evt.target.id).style.color = myVeryLightMainColor;
		}
	}

	handleEvent(evt) {
		if (evt.target.id) {
			if (evt.target.id == 'zero'|| evt.key == '0') {
				eventTargetId = 0;
			}
			else {
			eventTargetId = (parseInt(evt.target.id));
			}
			if (eventTargetId >= 0 && eventTargetId <= 9) {
				this.setState({ myProjectVisible: eventTargetId });
			}
		}
		else if (evt.keyCode) {
			if (evt.keyCode == 48) {
				eventKey = 0;
			}
			else {
			eventKey = (parseInt((evt.keyCode - 48)));
			}
			if (eventKey > 0 && eventKey <= 9) {
				document.getElementById(eventKey).click();
			}
			else if (eventKey == 0) {
				document.getElementById('zero').click();
			}
			else {
				console.log('Invalid input!');
			}
		}
	}

	render() {
		console.log('render');
		projectJSONNumber = (parseInt(this.state.myProjectVisible));
		if (projectJSONNumber >= 1) {
			const digitClassNames = ClassNames('number', 'visible');
			document.getElementById(projectJSONNumber).className = digitClassNames;
		}
		return (
      <div className='projects'>
				<div className='lecLogoContainer'>
					<Link activeClass="active" className="scrollLink" to="currentProject" spy={true} smooth={true} duration={700}>
						<img
							src='resources/lecLogo.svg'
							onMouseOver={this.makeItLight.bind(this)}
							onMouseOut={this.makeItDark.bind(this)}
							id='zero'
							className='lecLogo'
							onClick={this.handleEvent.bind(this)}>
						</img>
					</Link>
				</div>
				<div className='icons'>
					<a href='mailto:klinskipaul@gmail.com'>
						<img
							src='resources/mailIcon.svg'
							onMouseOver={this.makeItLight.bind(this)}
							onMouseOut={this.makeItDark.bind(this)}
							id='mailIcon'
							className='icon'>
						</img>
					</a>
					<a target="_blank" href="https://www.facebook.com/PaulKlinski">
						<img
							src='resources/facebookIcon.svg'
							onMouseOver={this.makeItLight.bind(this)}
							onMouseOut={this.makeItDark.bind(this)}
							id='facebookIcon'
							className='icon'>
						</img>
					</a>
					<a target="_blank" href="https://github.com/Logetcrea">
						<img
							src='resources/githubIcon.svg'
							onMouseOver={this.makeItLight.bind(this)}
							onMouseOut={this.makeItDark.bind(this)}
							id='githubIcon'
							className='icon'>
						</img>
					</a>
				</div>
						<div className='typeANumber'>type a number between 1 and 9 to switch through my projects</div>
						<div className='projectLinkNumbers'>
						<div className='numbers'>
						<Link activeClass="active" className="scrollLink" to="currentProject" spy={true} smooth={true} duration={700}>
						{myProjects.map((images, index) => {
							return (
								<div className='number'
									id={index}
									onClick={this.handleEvent.bind(this)}>
									{index}
								</div>
						)})}
						</Link>
						</div>
						</div>
				<Element name='currentProject' className='scrollElement'>
				{this.state.myProjectVisible > -1 ?
				<div className='project'>
					<div className='title'>
						{myProjects[projectJSONNumber].title}
					</div>
					{projectJSONNumber == 0 ? '' :
						<div className='projectNumber'>
							{projectJSONNumber}
						</div>
					}

					{myProjects[projectJSONNumber].titleVideo ?
						<div className="titleVideoContainer">
							<iframe
								frameBorder="0"
								className="titleVideo"
								scrolling="no"
								src={myProjects[projectJSONNumber].titleVideo}>
							</iframe>
						</div> :
						<img
							className='titleImage'
							src={myProjects[projectJSONNumber].titleImage}>
						</img>
					}
					{myProjects[projectJSONNumber].title == 'LogEtCrea' ?
						<div>
								<div className='descriptionContainer'>
									<div className='description'>
										<div className='aboutMeTitle'>
											Who I Am
										</div>
										<div className='aboutMe'>
											{myProjects[projectJSONNumber].WhoIAm[0]}
										</div>
									</div>
									<div className='description'>
										<div className='aboutMeTitle'>
											Why Log et Crea
										</div>
										<div className='aboutMe'>
											<div className='aboutMeQuote'>
												{myProjects[projectJSONNumber].WhyLogEtCrea[0]}
											</div>
											<div className='aboutMeAuthor'>
												{myProjects[projectJSONNumber].WhyLogEtCrea[1]}
											</div>
											<br />
											{myProjects[projectJSONNumber].WhyLogEtCrea[2]}
										</div>
									</div>
								</div>
								<div className='address'>
									{myProjects[projectJSONNumber].address.map((adressSnippets, index) => {
										return (
											<div className='addressSnippet'>{myProjects[projectJSONNumber].address[index]}</div>
									)})}
								</div>
						</div> :
						<div>
					{myProjects[projectJSONNumber].description ?
						<div className='descriptionContainer'>
							<div className='descriptionTitle'>description</div>
							{projectJSONNumber == 0 ?
								'' :
									myProjects[projectJSONNumber].description.map((descriptions, index) => {
										return (
											<div className='description'>{myProjects[projectJSONNumber].description[index]}</div>
									)})
							}
						</div>
					: ''}
					{myProjects[projectJSONNumber].images ?
						<div className='images'>
							{myProjects[projectJSONNumber].imagesTitle ?
								<div className='descriptionTitle'>
									{myProjects[projectJSONNumber].imagesTitle}</div>
							: '' }
							{myProjects[projectJSONNumber].images.map((images, index) => {
								return (
									<img
										className='image'
										src={myProjects[projectJSONNumber].images[index]}>
									</img>
							)})}
						</div>
					: ''}
					{myProjects[projectJSONNumber].fullSizeImages ?
						<div className='fullSizeContainer'>
							{myProjects[projectJSONNumber].fullSizeImagesTitle ?
								<div className='descriptionTitle'>
									{myProjects[projectJSONNumber].fullSizeImagesTitle}</div>
							: '' }
							{myProjects[projectJSONNumber].fullSizeImages.map((images, index) => {
								return (
									<img
										className='fullSizeImage'
										src={myProjects[projectJSONNumber].fullSizeImages[index]}>
									</img>
							)})}
						</div>
					: ''}
					{myProjects[projectJSONNumber].moreImages ?
						<div className='images'>
							{myProjects[projectJSONNumber].nextImagesTitle ?
								<div className='descriptionTitle'>
									{myProjects[projectJSONNumber].nextImagesTitle}</div>
							: '' }
							{myProjects[projectJSONNumber].moreImages.map((moreImages, index) => {
								return (
									<img
										className='image'
										src={myProjects[projectJSONNumber].moreImages[index]}>
									</img>
							)})}
						</div>
					: ''}
					{myProjects[projectJSONNumber].contributors ?
						<div className='contributorsContainer'>
							<div className='contributorsTitle'>contributors</div>
							{myProjects[projectJSONNumber].contributors.map((allContributors, index) => {
								return (
									<a
										id={index + 10}
										target="_blank"
										href={myProjects[projectJSONNumber].contributorLinks[index]}
										className='contributors'
										onMouseOver={this.makeItLight.bind(this)}
										onMouseOut={this.makeItDark.bind(this)}
										>
										{myProjects[projectJSONNumber].contributors[index]}
									</a>
								)})}
						</div>
					: ''}
					{myProjects[projectJSONNumber].linkNames ?
						<div className='linkContainer'>
							<div className='linkTitle'>link</div>
							{myProjects[projectJSONNumber].linkNames.map((allLinks, index) => {
								return (
									<a
										id={index + 10}
										target="_blank"
										href={myProjects[projectJSONNumber].links[index]}
										className='links'
										onMouseOver={this.makeItLight.bind(this)}
										onMouseOut={this.makeItDark.bind(this)}
										>
										{myProjects[projectJSONNumber].linkNames[index]}
									</a>
								)})}
						</div>
					: ''}
					<div className='copyright'>© Copyright {year} Paul Klinski</div>
				</div>}
				</div>
				: '' }
				</Element>
			</div>
		)
	}
}

export default Projects;
