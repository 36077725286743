import React, { PropTypes } from 'react';
import ReactDOM from 'react-dom';
import Header from './Header.es6';
import Projects from './Projects.es6';

class Site extends React.Component {

	constructor() {
		super();
	}

	render() {

    ReactDOM.render(
			<div className='site'>
			<div>
				<Header></Header>
				<Projects></Projects>
			</div>
			</div>
      , document.getElementById('app')
    );

	}
}

export default Site;
