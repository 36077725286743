module.exports=[
  {
    "title": "LogEtCrea",
    "titleImage": "resources/logetcrea/background.jpg",
    "WhoIAm": [
        "In 2013 I made my high school graduation at the Ev. Gym. zum Grauen Kloster in Berlin, Germany. This school focusses on origins and reasons through teaching ancient languages like Latin, Ancient Greek and Ancient Hebrew. This focus characterized me a lot. In my job and passion as a Designer I always want to know where something comes from and how it works. I explore new topics very often, that’s why I learned JavaScript."
      ],
    "WhyLogEtCrea": [
        "There's so much grey to every story, nothing is so black and white.",
        "Lisa Ling",
        "So if there would be no white, no color could exist but black. Black needs white and the other way around. Same with logical and creative thinking, that’s why I try to cover both, LogEtCrea. Illuminated Experience Design."
      ],
    "address": [
      "Paul Klinski",
      "Kastanienallee 38",
      "14471 Potsdam, Germany"
    ]
  },
  {
    "title": "clubguide",
    "titleVideo": "https://player.vimeo.com/video/158482161?autoplay=1",
    "description": [
        "The Idea was to create an intelligent Party-Searching-App. The App should know what you like and wich friends do it the same; for connecting and having fun together. Besides the App should find events by searching for genres.",
        "ClubGuide is connected with Facebook and shows your events and those of your friends. You are able to create lists for your preferred clubs, artists or events. The CalenderView gives you an overview of your PartyWeek. This is just a clickable prototype developed with Framer.js and Sketch."
      ],
    "images": [
      "resources/projects/clubguide/clubGuideScreens1.jpg",
      "resources/projects/clubguide/clubGuideScreens2.png",
      "resources/projects/clubguide/clubGuideScreens3.jpg",
      "resources/projects/clubguide/clubGuideScreens4.jpg"
    ],
    "fullSizeImages": [
      "resources/projects/clubguide/mockupEventScreen.jpg"
    ]
  },
  {
    "title": "from letter to picture",
    "titleVideo": "http://logetcrea.github.io/from-letter-to-picture/canvas.html",
    "description": [
        "In this project I am trying to translate the different layout types of letters to artificial coded pictures. I categorized different containers like the addressee, date, sender and the text. In my research I figured out, there are mostly just a few different positions for each container. I calculated the possibilities for each container being at certain positions.",
        "Now I wanted to visualize the different contents of letters, so I translated the writing into my animation through letting the containers rotating around theirselves and around a certain radius. To implement the different amounts of text I set some random variables which setting the radius of rotation. To generate a picture or check out the code visit my github page. This visualization was made with P5.js."
      ],
    "images": [
      "resources/projects/letter2pic/letterScreens01.png",
      "resources/projects/letter2pic/letterScreens02.png",
      "resources/projects/letter2pic/01-progress.png",
      "resources/projects/letter2pic/02-progress.png",
      "resources/projects/letter2pic/03-progress.png",
      "resources/projects/letter2pic/04-progress.png"
    ],
    "fullSizeImages": [
      "resources/projects/letter2pic/05-progress.png",
      "resources/projects/letter2pic/06-progress.png"
    ],
    "moreImages": [
      "resources/projects/letter2pic/07-progress.png",
      "resources/projects/letter2pic/08-progress.png",
      "resources/projects/letter2pic/livingRoom.png",
      "resources/projects/letter2pic/myFountain.jpg"
    ],
    "linkNames": [
      "GitHub"
    ],
    "links": [
      "https://github.com/Logetcrea/from-letter-to-picture"
    ]
  },
  {
    "title": "the quality of water",
    "titleImage": "resources/projects/mappingZebras/waterQuality.png",
    "titleImageLink": "https://klinskipaul.carto.com/viz/adfd2266-738a-11e5-87a9-0ecfd53eb7d3/embed_map",
    "description": [
      "The industry impures our water. This you may hear often. I wanted to get to the bottom of this through a little data visualization.",
      "I took values of the waterquality, measured every kilometer, regarding to recommendation of swimming and contrasted those to the biggest industries in germany. The interpretation is up to you. For more detail and the process visit my github page. This visualization was made with P5.js."
      ],
    "images": [
      "resources/projects/mappingZebras/berlin.jpg",
      "resources/projects/mappingZebras/hamburg.png"
    ],
    "fullSizeImages": [
      "resources/projects/mappingZebras/mockupWaterQualiDesktop.jpg"
    ],
    "linkNames": [
      "cartoDB"
    ],
    "links": [
      "https://klinskipaul.carto.com/viz/adfd2266-738a-11e5-87a9-0ecfd53eb7d3/public_map"
    ]
  },
  {
    "title": "mindshare",
    "titleImage": "resources/projects/mindShare/mockupMindShareClean.jpg",
    "description": [
        "Everybody knows the trouble with working in a team. The bigger the team, the worse the communication gets. One million awesome ideas flying around and nobody has an overview about those and their pros and cons.",
        "This little app organizes your ideas relating to your created projects. Create a profile and start working. A ranking and a chat helps to communicate pros and cons. A timer supports you to never miss a deadline. This App is just a visual Prototype made with Illustrator and Photoshop."
      ],
    "images": [
      "resources/projects/mindShare/mindShareScreens01.png",
      "resources/projects/mindShare/mindShareScreens02.png"
    ],
    "fullSizeImages": [
      "resources/projects/mindShare/mockupMindShare.jpg"
    ],
    "contributors": [
      "Christopher Klement"
    ],
    "contributorLinks": [
      "http://www.chrisklement.com/"
    ]
  },
  {
    "title": "paperspace",
    "titleImage": "resources/projects/paperSpace/mockupPaperSpaceClean.jpg",
    "description": [
        "The time of paper is not over yet! Often you get letters from your insurances or any bills on paper. But you are smart and know the problems with the medium paper: You can lose a piece of paper very easily, folders to sort and organize papers are huge and ugly in your cupboard, it is annoying in times like these to search analogue, when we already know the search-functionalities of a computer, and a lot of other papers already have been sent digital and you want to merge them.",
        "Our easy app solves all these problems. The only thing you need to do is scanning your documents and tagging them for better finding. You can sort your documents in self created folders and they automatically get sorted by time. Through opening in other apps like pages allows you to answer the letter easily, the whole conversation bundled. This is just a clickable Prototype made with Axure, Illustrator and Photoshop."
      ],
    "images": [
      "resources/projects/paperSpace/01scribble.jpg",
      "resources/projects/paperSpace/02scribble.jpg",
      "resources/projects/paperSpace/paperSpaceScreens01.png",
      "resources/projects/paperSpace/paperSpaceScreens02.png",
      "resources/projects/paperSpace/01-paperSpaceLogo.svg",
      "resources/projects/paperSpace/02-paperSpaceLogo.svg"
    ],
    "fullSizeImages": [
      "resources/projects/paperSpace/mockupPaperSpace.jpg"
    ],
    "contributors": [
      "Julian Wohlleber"
    ],
    "contributorLinks": [
      "https://www.xing.com/profile/Julian_Wohlleber"
    ]
  },
  {
    "title": "research matters",
    "titleImage": "resources/projects/researchMatters/allProjects.png",
    "titleImageLink": "http://logetcrea.github.io/research-matters/index.html",
    "description": [
        "Did you ever wonder, wtf they are doing on board of the ISS? I did! They are doing a looooooot of research out there! But the website of the NASA is confusing and difficult to compare. You can’t see your desired information at once. The page works mostly about scrolling, clicking, scrolling and clicking. Thats why I started to visualize the research data through categorizing, sorting and mapping.",
        "Every research executed on the ISS has been requested by universities, governments, investigators… So I mapped the geolocation of the requester and sorted by the different topics. Now you can get a great overview about the project topics. The next step is to enable more interaction like getting information about certain researches through hovering and clicking. For more detail and the process visit my cartoDB page."
      ],
    "images": [
      "resources/projects/researchMatters/bio.png",
      "resources/projects/researchMatters/bioText.png"
    ],
    "linkNames": [
      "GitHub"
    ],
    "links": [
      "https://github.com/Logetcrea/research-matters"
    ]
  },
  {
    "title": "sony action cam redesign",
    "titleImage": "resources/projects/sonyCam/sonyWideRedesign.png",
    "description": [
        "Creating an intuitive, understandable and all in all great user experience is difficult. Often it needs a lot of research, test users and iterations. The action cam HDR-AS20 from SONY hasn’t Game of Thrones a great design in my opinion. You don’t really understand where in the hierarchy and position you are at the moment. Also switching between taking a video and or photo is unnecessary complicated. My idea was to keep as much as possible as it is with completely changing and improving the user experience and design.",
        "Through testing I figured out that adding an additional video button would be a nice improvement for easily switching the status. The current interface only displays the selected step in the hierarchy but not where you come from and where you might go. I decided to display always next parent in the hierarchy and compare all children of one parent that you directly understand the differences and are able to choose the one of your desire. This visualization was made with Illustrator and Photoshop."
      ],
    "imagesTitle": "sony's design",
    "images": [
      "resources/projects/sonyCam/oldDesign/side.png",
      "resources/projects/sonyCam/oldDesign/front.png"
    ],
    "nextImagesTitle": "my redesign",
    "moreImages": [
      "resources/projects/sonyCam/redesign/redesign-startscreen-play.png",
      "resources/projects/sonyCam/redesign/front.png",
      "resources/projects/sonyCam/redesign/redesign-setupscreenvideo.png",
      "resources/projects/sonyCam/redesign/redesign-recscreen-0004.png"
    ]
  },
  {
    "title": "TRANSL8R",
    "titleVideo": "https://player.vimeo.com/video/157480156?autoplay=1",
    "description": [
        "IoT. The internet of things. TRANSL8R is a chain of multilingual machines to play the Chinese whispers game with, meant to be shown in an exhibition context. You might think this is an „only human“ thing. But in this project we want to translate this game into machine-language. Machines resemble humans more and more. They become programmed thinking and solving problems based on experienced situations. But machines can only as good and clever as the programmers code. Language is very complex and also always changing. Online translating services translated by machines are still worse than educated humans.So we decided to focus on that topic and let machines translate a sentence and than further and further always with the new understood sentence.",
        "The difference compared to the game with humans is that the translation is difficult not the pronunciation. TRANSL8R consists of four boxes. The first box listens to the visitor's message and repeats it out loud. The second box listens to the first one and translates that message into a different language. The third and fourth one each do the same (every box speaks a different language). Finally, the first box translates the message back into the original language. In an ideal case, that message would be the same as the original one, but the little mistakes of the translation engine make for a funny ending, just as in the Chinese whispers game. My job in this project was to programm and solder the hardware and to design the mechanics."
      ],
    "images": [
      "resources/projects/transl8r/physicalComp.jpg",
      "resources/projects/transl8r/TRANSL8R-circuit.png",
      "resources/projects/transl8r/TRANSL8R-How-to-1.png",
      "resources/projects/transl8r/TRANSL8R-How-to-2.png"
    ],
    "contributors": [
      "Daria Thies",
      "Jo Swarzynska",
      "Julia Freyhoff",
      "Laura Pau Bielsa",
      "Marta Carlesso",
      "Nina Komarova-Zelinskaya",
      "Andre Nagusch",
      "Bela Kurek",
      "Dustin Kummer",
      "Joshua Pacheco",
      "Lucas Vogel",
      "Nikolas Ripka",
      "Wolfgang Albrecht"
    ],
    "contributorLinks": [
      "http://dariadunkelbier.de/",
      "http://www.jopicture.com/",
      "https://github.com/antsteelmule",
      "http://paubielsa-blog.tumblr.com/",
      "https://incom.org/profil/6337",
      "https://github.com/Ninotschka",
      "https://de.linkedin.com/in/marc-andre-nagusch-67066a75",
      "https://incom.org/profil/7123",
      "https://github.com/miduku",
      "https://incom.org/profil/7029",
      "http://www.vogelino.com/",
      "https://incom.org/profil/7134",
      "https://incom.org/profil/6385"
    ],
    "linkNames": [
      "GitHub"
    ],
    "links": [
      "https://github.com/FH-Potsdam/connecting-bits"
    ]
  },
  {
    "title": "towerhead",
    "titleImage": "resources/projects/Unity/towerHeadTitle.png",
    "description": [
        "There are a lot games out there: Role-Playing, Strategy, Platforming, Shooter, Tower Defense, Racing, … And there are also a few perspectives, mainly these: 1st person, 3rd person and an objective perspective. We wanted to combine a game with an untypical perspective to reach a completely other game experience. We chose The game category Tower Defense, which normally is displayed in a objective perspective, and combined it with a 3rd person perspective. The idea was to get deeper into the game and sympathize through the character with the game itself.",
        "Story of the game: London. Ghosts trying to conquer the city. You are a Bobby, a policeman, and need to defend the city together with the sights of London. Level per level the battle expands and you can get the ghost farther from the earth, into the universe and to other planets. You can upgrade and sell your towers. But to win the game you need to place your towers wisely and carefully… My job was to combine different code snippets, make 3D objects, map images and videos and to design the different levels."
      ],
    "images": [
      "resources/projects/Unity/scribble1.svg",
      "resources/projects/Unity/scribble2.svg",
      "resources/projects/Unity/ghost.png",
      "resources/projects/Unity/policeman.jpg"
    ],
    "fullSizeImages": [
      "resources/projects/Unity/level2.png",
      "resources/projects/Unity/level3.png",
      "resources/projects/Unity/mockupTowerHead.jpg"
    ],
    "contributors": [
      "Julian Wohlleber",
      "Jasper Precht"
    ],
    "contributorLinks": [
      "https://www.xing.com/profile/Julian_Wohlleber",
      "http://jasperprecht.de/"
    ]
  }
]
